

/*--------------------------------------------------------------
3. content
--------------------------------------------------------------*/

/*3.1 wpo-hero-slider*/

.wpo-hero-section-1{
	position: relative;
	height: 900px;
	background: #edf2f8;
	display: flex;
	justify-content: center;
	flex-direction: column;
	z-index: 1;

	&:before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		z-index: -1;
		height: 100%;
		width: 67%;
		background: #edf2f8;
		-webkit-clip-path: polygon(0 0, 75% 0, 90% 100%, 0 100%);
		clip-path: polygon(0 0, 75% 0, 90% 100%, 0 100%);
	}
	
	.right-img{
	  position: absolute;
	  right: 0;
	  top: 0;
	  height: 900px;
	  width: 50%;
	  z-index: -11;
	  background: url(../../images/slider/slide-1.jpg) no-repeat right center;

	  @media(max-width:1440px){
		top: -30px;
	  }
	  @media(max-width:1200px){
		top: 0px;
	  }
	}
	
	.right-img2 {
		position: absolute;
		left: 60px;
		bottom: -1px;
		z-index: 1;
	}
	.right-img img{
	  height: 100%;
	  width: 100%;
	}
  
	.wpo-hero-title h2{
	  font-size: 70px;
	  font-weight: 900;
	  line-height: 90px;
	  margin:10px 0 25px;
	}
	.wpo-hero-title h2 span{
	  color: $theme-primary-color;
	}

	.wpo-hero-title-top{
		span{
			font-size: 22px;
			color: $light;
			font-family: $heading-font;
		}
	}

	.wpo-hero-subtitle{
		p{
			font-size: 25px;
			color: $text-light-color;
			font-family: $heading-font;
			line-height: 35px;
			max-width: 595px;
			padding-left: 20px;
			border-left: 2px solid $text-light-color;
			margin-bottom: 60px;
		}
	}
  }



.wpo-hero-section-text{
	position: relative;

	.video-holder .video-btn{
		button.wrap{
			position: absolute;
			right:-80px;
			top: 50%;
			transform: translate(-50%, -50%);
			display: block;
			width: 84px;
			height: 84px;
			background: $white;
			text-align: center;
			line-height: 90px;
			color: $theme-primary-color;
			font-size: 30px;
			border-radius: 50%;
			border: 0;

			&:before{
				content: " ";
				width: 130%;
				height: 130%;
				background:rgba($color: #fff, $alpha: 0.3);
				border-radius: 50%;
				position: absolute;
				left: -12px;
				top: -12px;
				-webkit-animation: pulse 1s infinite;
				animation: pulse 1s infinite;
				z-index: -1;
			}

			.fi{
				font-size: 25px;
			}
		}

	}
}

@-webkit-keyframes pulse {
	from {
	  -webkit-transform: scale3d(1, 1, 1);
	  transform: scale3d(1, 1, 1);
	}
  
	50% {
	  -webkit-transform: scale3d(1.05, 1.05, 1.05);
	  transform: scale3d(1.05, 1.05, 1.05);
	}
  
	to {
	  -webkit-transform: scale3d(1, 1, 1);
	  transform: scale3d(1, 1, 1);
	}
  }
  
  @keyframes pulse {
	from {
	  -webkit-transform: scale3d(1, 1, 1);
	  transform: scale3d(1, 1, 1);
	}
  
	50% {
	  -webkit-transform: scale3d(1.05, 1.05, 1.05);
	  transform: scale3d(1.05, 1.05, 1.05);
	}
  
	to {
	  -webkit-transform: scale3d(1, 1, 1);
	  transform: scale3d(1, 1, 1);
	}
  }




/*--------------------------------------------------------------
hero slider s2
--------------------------------------------------------------*/

.wpo-hero-section-1.wpo-hero-section-2{
	background: $white;
	height: 900px;

	@media(max-width:991px){
		background: #edf2f8;
	 }

	&:before{
		display: none;
	}

	.right-vec{
		z-index: 1;
		.right-img{
			position: absolute;
			z-index: -11;
			background: none;

			@media(max-width:991px){
               display: block;
			   position: relative;
			   height: unset;
			}

			.r-img{
				position: absolute;
				right: 60px;
				top: 50%;
				transform: translateY(-50%);
				img{
					@media(max-width:991px){
						display: none;
					 }
				}
			}

			img{
				width: 100%;
				height: unset;
			}
	
			.wpo-happy-client{
				position: absolute;
				left: -50px;
    			bottom: 20%;
				padding: 45px 30px;
				background: $white;
				border-radius: 20px;
				box-shadow: 0px 1px 20px 0px rgba(43, 45, 118, 0.1);
				display: flex;
				align-items: center;
				z-index: 99;

				h3{
					font-size: 52px;
					font-family: $heading-font-sub;
					margin-bottom: 0px;
				}
				p{
					margin-bottom: 0;
					font-size: 22px;
					color: #838383;
					font-family: $heading-font-sub;
				}
	
				.wpo-happy-client-slide{
					max-width: 216px;
					margin-left: 25px;
					list-style: none;
					display: flex;

					.owl-nav{
						display: none;
					}
	
					li{
						width: 55px;
						height: 55px;

						img{
							width: 55px;
							height: 55px;
							@include rounded-border(50%);
						}
					}
				}
			}
			  
		}

		.svg-shape{
			position: absolute;
			bottom: -10%;
			width: 100%;
			right: 100px;
			z-index: -111;
			svg{
				width: 100%;
			}

			@media(max-width:1440px){
				bottom: -20%;
			}
		}

	}


	.video-holder {
		button.wrap {
			position: absolute;
			right:20%;
			top: 50%;
			transform: translate(-50%, -50%);
			z-index: 999;
			display: block;
			width: 84px;
			height: 84px;
			background: $white;
			text-align: center;
			line-height: 90px;
			color: $theme-primary-color;
			font-size: 30px;
			border-radius: 50%;
			border: 0;

			&:before{
				content: " ";
				width: 130%;
				height: 130%;
				background:rgba($color: #fff, $alpha: 0.3);
				border-radius: 50%;
				position: absolute;
				left: -12px;
				top: -12px;
				-webkit-animation: pulse 1s infinite;
				animation: pulse 1s infinite;
				z-index: -1;
			}

			.fi{
				font-size: 25px;
			}
		}

	}

	  
}


.wpo-hero-slider {
	width: 100%;
	height: 900px;
	display: flex;
	position: relative;
	z-index: 0;

	@include media-query(991px) {
		height: 600px;
	}

	@include media-query(767px) {
		height: 500px;
	}

	.hero-slide{
		height: 900px;
		
		@include media-query(991px) {
			height: 600px;
		}
	
		@include media-query(767px) {
			height: 500px;
		}
	}

	.hero-container {
		width: 100%;
		height: 100%;
		position: absolute;
		left: 0;
		top: 0;
	}

	.slide-inner{
		width: 100%;
		height: 100%;
		z-index: 1;
		background-size: cover;
		background-position: center;
		display: flex;
		justify-content: center;
		align-items: center;
		text-align: left;

		.slide-content{
			padding-bottom: 70px;

			@media(max-width:991px){
              padding-bottom: 0;
			}
		}
	}
	.slick-prev,
	.slick-next {
		background-color: transparentize($theme-primary-color, 0.3);
		width:45px;
		height: 45px;
		z-index: 10;
		@include rounded-border(50%);
		opacity: 0;
		visibility: hidden;
		transition: all .3s;
		border: 2px solid $theme-primary-color;

		&:hover{
			background-color: $theme-primary-color;
		}
	}

	.slick-prev {
		left: 0px;

		@include media-query(767px) {
			display: none !important;
		}

		&:before {
			font-family: "themify";
			content: "\e629";
			opacity: 1;
		}
	}

	.slick-next {
		right: 0px;

		@include media-query(767px) {
			display: none !important;
		}

		&:before {
			font-family: "themify";
			content: "\e628";
			opacity: 1;
		}
	}

	&:hover{

		.slick-next {
			right: 20px;
			opacity: 1;
			visibility: visible;
		}

		.slick-prev {
			left: 20px;
			opacity: 1;
			visibility: visible;
		}
	}


}


.wpo-hero-slider {

	@include media-query(1199px) {
		height: 680px;
	}

	@include media-query(991px) {
		height: 600px;
	}

	@include media-query(767px) {
		height: 500px;
	}


	.gradient-overlay {
		@include linear-gradient-bg(to left, $dark-gray 45%, transparent 65%);
		width: 100%;
		height: 100%;
		position: absolute;
		left: 0;
		top: 0;
		z-index: 10;
		opacity: .6;
	}

	.gradient-overlay + .container {
		position: relative;
		z-index: 11;
	}

	.swiper-slide {
		position: relative;
		z-index: 11;
	}

	.wpo-hero-title-top{
		span{
			font-size: 22px;
			color: #e4e4e4;
			font-family: $heading-font;

			@include media-query(767px) {
				font-size: 15px;
			}
	
		}
	}

	.slide-title {
		max-width: 655px;

		@include media-query(1199px) {
			max-width: 555px;
		}


		h2 {
			font-size: 70px;
			font-weight: 900;
			line-height: 90px;
			margin:10px 0 25px;
			color: $white;

			@include media-query(1199px) {
				font-size: 50px;
				font-size: calc-rem-value(50);
			}

			@include media-query(991px) {
				font-size: 40px;
				font-size: calc-rem-value(40);
				line-height: 55px;
			}

			@include media-query(767px) {
				font-size: 30px;
				font-size: calc-rem-value(30);
				line-height: 36px;
			}
		}
	}

	.slide-text {
		max-width: 520px;

		@include media-query(767px) {
			max-width: 500px;
		}
		
		p {
			font-size: 25px;
			color: $text-light-color;
			font-family: $heading-font;
			line-height: 35px;
			max-width: 595px;
			padding-left: 20px;
			border-left: 2px solid #e2e2e2;
			margin-bottom: 60px;
			color: #e2e2e2;

			@include media-query(991px) {
				font-size: 18px;
				font-size: calc-rem-value(18);
			}

			@include media-query(767px) {
				font-size: 16px;
				font-size: calc-rem-value(16);
				line-height: 22px;
				margin-bottom: 30px;
			}
		}		
	}

	.slide-btns {
		@include media-query(991px) {
			max-width: 400px;
		}
	}


	.slide-btns .hero-video-btn {
		margin-left: 40px;

		@include media-query(767px) {
			margin-left: 20px;
		}
	}
}



@media(max-width: 1700px) {
	.wpo-hero-section-1 .right-img2 {
		left: 38px;
	}
  }
  
  
  @media(max-width: 1500px) {
	.wpo-hero-section-1 .right-img2 {
		left: 18px;
	}
  
	.wpo-hero-section-1.wpo-hero-section-2 .right-vec .right-img .r-img {
		  right: 20px;
	  }
  
  }

  @media(max-width: 1200px) {

	.wpo-hero-section-1 .right-img2 {
		left: 0;
	}
  
	.wpo-hero-section-1.wpo-hero-section-2 .wpo-hero-title h2 {
		  font-size: 45px;
		  line-height: 50px;
	  }
  
	  .wpo-hero-section-1.wpo-hero-section-2 {
		  height: 700px;
	  }
  
	  .wpo-hero-section-1.wpo-hero-section-2 .right-vec .right-img .r-img {
		  right: 20px;
		  top: 35%;
	  }
	  .wpo-hero-section-1.wpo-hero-section-2 .right-vec .right-img .wpo-happy-client {
		  position: absolute;
		  left: -85px;
		  bottom: 35%;
	  }
	}
	
	@media(max-width: 991px) {
	.wpo-hero-section-1 .right-img2 {
		left: -15px;
	}
	
	.wpo-hero-section-text .video-holder {
		position: absolute;
		left: 210px;
		bottom: -35px;
		top: auto;
		right: auto;
	}
	
	.wpo-hero-section-text .video-holder a {
		width: 60px;
		height: 60px;
		line-height: 60px;
	}
	
	.wpo-hero-section-text .video-holder a .fi {
		font-size: 20px;
	}
	
	.wpo-hero-section-text .video-holder a:before {
		left: -9px;
		top: -9px;
	}
	
	.right-img {
		display: none;
	}
	
	.wpo-hero-section-1 {
		height: 700px;
	}
	
	.wpo-hero-section-1.wpo-hero-section-2 .right-vec .right-img {
			padding-top: 20px;
			margin-top: 50px;
			border-top: 1px solid #ffff;
			padding: 20px;
		}
	
		.wpo-hero-section-1.wpo-hero-section-2 .right-vec .right-img .wpo-happy-client {
			position: relative;
			left: 0;
			bottom: -30px;
			width: 450px;
			left: 0;
			margin: 0 auto;
		}
	
		.wpo-hero-section-1.wpo-hero-section-2 .right-vec .svg-shape{
			display: none;
		}
	
		.wpo-hero-section-1.wpo-hero-section-2 .right-vec .right-img .video-holder {
			position: relative;
			left: -80px;
			top: 80px;
		}
	
		.wpo-hero-section-1.wpo-hero-section-2 .right-vec .right-img .video-holder a {
			width: 60px;
			height: 60px;
			line-height: 65px;
		}
	
		.wpo-hero-section-1.wpo-hero-section-2 .right-vec .right-img .r-img {
			left: 30%;
			top: -45px;
			right: auto;
		}
	
		.wpo-hero-section-1.wpo-hero-section-2 .right-vec .right-img .video-holder a:before {
			left: -8px;
			top: -8px;
		}
	
		.wpo-hero-section-1.wpo-hero-section-2 .right-img{
			width: 100%;
		}
	
		.wpo-hero-section-1.wpo-hero-section-2 .right-vec .right-img .video-holder {
			left: 60%;
			top: 0;
		}

		.wpo-hero-section-1.wpo-hero-section-2 .video-holder button.wrap{
			display: none;
		}
		.wpo-hero-section-text .video-holder .video-btn button.wrap{
			display: none;
		}

	}


	@media (max-width: 767px) {

		.wpo-hero-section-1 .wpo-hero-title h2 {
			font-size: 50px;
			line-height: 50px;
		}
	  
		.wpo-hero-section-1.wpo-hero-section-2 .right-vec .right-img .wpo-happy-client {
		  width: 100%;
		  padding: 18px 15px;
		  display: block;
		  text-align: center;
		  bottom: -15px;
	   }
	  
	  .wpo-hero-section-1.wpo-hero-section-2 .right-vec .right-img .wpo-happy-client .wpo-happy-client-slide {
		  max-width: 216px;
		  margin-left: 25px;
		  margin: 0 auto;
		  margin-top: 10px;
	  }
	  
		.wpo-hero-section-1.wpo-hero-section-2 .right-vec .right-img .r-img {
			left: 265px;
			top: -20%;
		}
		.wpo-hero-section-1.wpo-hero-section-2 {
			height: 750px;
		}
	
		.wpo-hero-section-1.wpo-hero-section-2 .right-vec .right-img .video-holder {
			left: 20px;
			top: 6px;
		}
	}

	@media (max-width: 680px) {
		.wpo-hero-section-1.wpo-hero-section-2 .right-vec .right-img .video-holder {
			left: -60px;
			top: 6px;
		}
	}
	
	@media (max-width: 1440px) {
		.wpo-hero-section-1.wpo-hero-section-2 .video-holder button.wrap {
			top: 40%;
		}
	}
	@media (max-width: 991px) {
		.modal-video-movie-wrap {
			width: 88%;
			margin: 0 auto;
		}
		.modal-video-close-btn{
			right: 0;
		}
	}
	

